import React, { useState, useEffect, useRef } from "react";
import {
	createUserWithEmailAndPassword,
	sendEmailVerification,
	setPersistence,
	signInWithEmailAndPassword,
	browserSessionPersistence,
	browserLocalPersistence,
	sendPasswordResetEmail,
	useDeviceLanguage,
	signOut,
} from "firebase/auth";
import { Card, Form, Button, InputGroup, FormControl, Alert, Overlay, Tooltip, Spinner } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import { setLabels } from "../../utilities/utilities";
import "./signin.css";

const SignIn = ({ auth, setAuthorized }) => {
	const [action, setAction] = useState("signin");
	const [processing, setProcessing] = useState();
	const [error, setError] = useState();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [authLocalPersistence, setAuthLocalPersistence] = useState();
	const [notice, setNotice] = useState();
	const [passwordInputType, setPasswordInputType] = useState("password");

	const signInForm = useRef();
	const emailInput = useRef();
	const passwordInput = useRef();

	useDeviceLanguage(auth);
	const firestore = useFirestore();

	useEffect(() => setLabels(signInForm.current), []);

	const submitAction = async (directAction) => {
		setError(undefined);
		setNotice(undefined);
		setProcessing(true);
		try {
			await setPersistence(auth, authLocalPersistence ? browserLocalPersistence : browserSessionPersistence);
			switch (directAction || action) {
				case "forgotPassword":
					await sendPasswordResetEmail(auth, email);
					setNotice({ variant: "info", message: `Se ha enviado un correo electrónico para el restablecimiento de la contraseña a ${email}` });
					break;
				case "signup":
					await createUserWithEmailAndPassword(auth, email, password);
					await firestore.doc(`users/${auth.currentUser.uid}`).set({ authorized: false, email });
				// break removed to sendEmailVerification after signup
				case "sendEmailVerification":
					await sendEmailVerification(auth.currentUser);
					setNotice({
						variant: "info",
						message: `Para iniciar sesión primero debes confirmar tu dirección de correo electronico haciendo clic en el enlace enviado a:<br/><br/><strong>${email}</strong>`,
					});
					break;
				default:
					const userCredential = await signInWithEmailAndPassword(auth, email, password);
					if (!userCredential.user.emailVerified)
						setError({
							code: "auth/unverified-email",
							email,
							password,
							message: "El correo electrónico no ha sido verificado, por favor haga clic en el enlace enviado a la dirección de correo electrónico",
						});
					else {
						const userRef = await firestore.doc(`users/${userCredential.user.uid}`).get();
						if (!userRef.exists || !userRef.data().authorized) {
							setNotice({ variant: "danger", message: `Usuario no autorizado` });
							await signOut(auth);
							setAuthorized(false);
						}
					}
					break;
			}
		} catch (error) {
			if (error?.code === "auth/too-many-requests") error.message = `Ha intentado muchas veces, por favor intente mas tarde`;
			setError({
				...error,
				email,
				password,
				message: error.customData?._tokenResponse?.error.message.replace("_", " ") || error.message.replace("Firebase: ", "").replace(`(${error.code}).`, ""),
			});
			// emailInput.current.setCustomValidity("asdasd");
		} finally {
			if (!auth.currentUser?.emailVerified) setProcessing(false);
		}
	};

	return (
		<div style={{ maxWidth: 400 }}>
			<Card>
				<Card.Body>
					<Form
						ref={signInForm}
						onSubmit={async (e) => {
							e.preventDefault();
							await submitAction();
						}}
					>
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon1">
								<i className="bi bi-envelope-fill"></i>
							</InputGroup.Text>
							<FormControl
								ref={emailInput}
								placeholder="Correo electrónico"
								type="email"
								required
								maxLength="250"
								disabled={!!processing}
								onChange={(e) => setEmail(e.target.value)}
								// onInput={() => emailInput.current.setCustomValidity("")}
							/>
							<Overlay
								target={emailInput.current}
								show={
									["auth/email-already-in-use", "auth/invalid-email", "auth/user-not-found", "auth/user-disabled", "auth/unverified-email"].includes(error?.code) &&
									error?.email === email
								}
								placement="bottom"
								rootClose={true}
								onHide={() => setError(undefined)}
							>
								{(props) => (
									<Tooltip {...props} className="alertTooltip">
										{error?.code === "auth/email-already-in-use" && "Un usuario con este correo electrónico ya esta registrado en el sistema"}
										{error?.code === "auth/invalid-email" && "Correo electrónico inválido, por favor incluya el simbolo @ y complete la dirección del correo electrónico"}
										{error?.code === "auth/user-not-found" && "No hay ningún usuario correspondiente a la dirección de correo electrónico"}
										{error?.code === "auth/user-disabled" && "Esta cuenta se encuentra actualmente deshabilitada"}
										{error?.code === "auth/unverified-email" && (
											<div>
												<a onClick={async () => await submitAction("sendEmailVerification")}>{error.message} o haga clic aqui para reenviar el enlace</a>
											</div>
										)}
									</Tooltip>
								)}
							</Overlay>
						</InputGroup>
						<InputGroup className="mb-3">
							<InputGroup.Text id="basic-addon1">
								<i className="bi bi-key-fill"></i>
							</InputGroup.Text>
							<FormControl
								ref={passwordInput}
								placeholder="Contraseña"
								type={passwordInputType}
								required={action !== "forgotPassword"}
								maxLength="30"
								disabled={!!processing}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<InputGroup.Text onClick={() => setPasswordInputType(passwordInput.current.getAttribute("type") === "password" ? "text" : "password")}>
								{passwordInputType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
							</InputGroup.Text>
							<Overlay
								target={passwordInput.current}
								show={["auth/weak-password", "auth/wrong-password"].includes(error?.code) && error?.password === password}
								placement="bottom"
								rootClose={true}
								onHide={() => setError(undefined)}
							>
								{(props) => (
									<Tooltip {...props} className="alertTooltip">
										{error?.code === "auth/weak-password" && "La contraseña debe tener al menos 6 caracteres"}
										{error?.code === "auth/wrong-password" && "Contraseña inválida"}
									</Tooltip>
								)}
							</Overlay>
						</InputGroup>
						{notice && (
							<Form.Group className="mb-3">
								<Alert variant={notice.variant}>
									<div dangerouslySetInnerHTML={{ __html: notice.message }}></div>
								</Alert>
							</Form.Group>
						)}
						<Form.Group className="mb-3">
							<label>
								<input
									type="checkbox"
									className="form-check-input"
									style={{ marginLeft: 12 }}
									onChange={() => setAuthLocalPersistence(!authLocalPersistence)}
									disabled={!!processing}
								/>
								&nbsp;Recordar este dispositivo
							</label>
						</Form.Group>
						<Form.Group className="mb-3">
							<Button
								type="submit"
								variant={action === "signin" ? "primary" : "link"}
								style={{ position: "absolute", bottom: 0, right: 0, margin: 16 }}
								onClick={() => setAction("signin")}
								disabled={!!processing}
							>
								Iniciar sesión
							</Button>
							<Button
								type="submit"
								variant={action === "forgotPassword" ? "primary" : "link"}
								// style={{ marginLeft: action === "forgotPassword" ? 0 : -12 }}
								onClick={() => setAction("forgotPassword")}
								disabled={!!processing}
							>
								Restablecer contraseña
							</Button>
							<br></br>
							<Button
								type="submit"
								variant={action === "signup" ? "primary" : "link"}
								style={{
									float: "left",
									// marginLeft: action === "signup" ? 0 : -12
								}}
								onClick={() => setAction("signup")}
								disabled={!!processing}
							>
								Registrarme
							</Button>
							{processing && <Spinner animation="border" size="sm" style={{ position: "absolute", left: "calc(50% - 8px)", bottom: 24 }} />}
						</Form.Group>
					</Form>
				</Card.Body>
			</Card>
			{error &&
				!["auth/email-already-in-use", "auth/invalid-email", "auth/user-not-found", "auth/user-disabled", "auth/unverified-email", "auth/weak-password", "auth/wrong-password"].includes(
					error?.code
				) && (
					<Alert variant="danger" className="shadow">
						{error.message}
					</Alert>
				)}
		</div>
	);
};

export default React.memo(SignIn);
