import React, { useEffect, useState } from "react";
import { Card, Form, Tabs, Tab } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Block from "../spaces/Block";

ChartJS.register(ArcElement, Tooltip, Legend);

const Rents = ({ minimumHours }) => {
	const [date, setDate] = useState(new Date());
	const [time, setTime] = useState(
		`${new Date().getHours().toString().padStart(2, 0)}:${new Date().getMinutes().toString().padStart(2, 0)}`
	);
	const [rentTimeFilterStart, setRentTimeFilterStart] = useState(
		new Date(new Date().setTime(new Date().getTime() - minimumHours * 60 * 60 * 1000)).getTime()
	);
	const [rentTimeFilterEnd, setRentTimeFilterEnd] = useState(
		new Date(new Date().setTime(new Date().getTime() + 60000)).getTime()
	);
	const [availabilityPerTown, setAvailabilityPerTown] = useState();
	const [selectedTown, setSelectedTown] = useState();
	const [availabilityPerNeighborhood, setAvailabilityPerNeighborhood] = useState();
	const [selectedNeighborhood, setSelectedNeighborhood] = useState();
	const [availabilityPerStreet, setAvailabilityPerStreet] = useState();
	const [selectedStreet, setSelectedStreet] = useState();
	const [availabilityPerBlock, setAvailabilityPerBlock] = useState();
	const [selectedBlock, setSelectedBlock] = useState();

	useFirestoreConnect([
		{
			collection: "areas",
			where: [
				["type", "==", "space"],
				["active", "==", true],
				["deleted", "==", false],
			],
			storeAs: "spaces",
		},
		{
			collection: "areas",
			where: [
				["type", "==", "town"],
				["active", "==", true],
				["deleted", "==", false],
			],
			storeAs: "towns",
		},
		{
			collection: "areas",
			where: [
				["type", "==", "neighborhood"],
				["active", "==", true],
				["deleted", "==", false],
			],
			storeAs: "neighborhoods",
		},
		{
			collection: "areas",
			where: [
				["type", "==", "street"],
				["active", "==", true],
				["deleted", "==", false],
			],
			storeAs: "streets",
		},
		{
			collection: "areas",
			where: [
				["type", "==", "block"],
				["active", "==", true],
				["deleted", "==", false],
			],
			storeAs: "blocks",
		},
		{
			collection: "rents",
			where: [
				["at", ">=", new Date(rentTimeFilterStart)],
				["at", "<=", new Date(rentTimeFilterEnd)],
			],
		},
	]);
	const spaces = useSelector(({ firestore: { ordered } }) => ordered.spaces);
	const rents = useSelector(({ firestore: { ordered } }) => ordered.rents);
	const towns = useSelector(({ firestore: { ordered } }) => ordered.towns);
	const neighborhoods = useSelector(({ firestore: { ordered } }) => ordered.neighborhoods);
	const streets = useSelector(({ firestore: { ordered } }) => ordered.streets);
	const blocks = useSelector(({ firestore: { ordered } }) => ordered.blocks);

	useEffect(() => {
		if (spaces && rents && towns) {
			setAvailabilityPerTown(
				spaces
					.filter(
						(s) =>
							!rents
								.map((r) => r.location)
								.flat()
								.includes(s.id)
					)
					.map((s) => {
						const space = { ...s };
						for (const town of towns) {
							if (s.containers.includes(town.id)) {
								space.town = town.name;
							}
						}
						return space;
					})
					.reduce((towns, space) => {
						if (!towns[space.town]) {
							towns[space.town] = {
								rgb: {
									red: Math.floor(Math.random() * 200),
									green: Math.floor(Math.random() * 200),
									blue: Math.floor(Math.random() * 200),
								},
								spaces: [space],
							};
						} else towns[space.town].spaces.push(space);
						return towns;
					}, {})
			);
		}
	}, [spaces, towns, rents]);

	return (
		<div style={{ maxWidth: 400 }}>
			<Card>
				<Card.Header>
					<Card.Title>Consultar ocupación</Card.Title>
				</Card.Header>
				{/* <Tabs>
					<Tab eventKey="date" title="Por fecha"> */}
				<Card.Body>
					<Card.Text>Seleccione fecha y hora de la consulta</Card.Text>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Form.Control
							type="date"
							onChange={(e) => {
								const dateParts =
									e.target.value && e.target.value.split("-").map((p) => parseInt(p));
								const newDate = dateParts
									? new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
									: new Date();
								setDate(newDate);
								const newRentTimeFilter = new Date(
									newDate.getFullYear(),
									newDate.getMonth(),
									newDate.getDate(),
									parseInt(time.split(":")[0]),
									parseInt(time.split(":")[1])
								);
								setRentTimeFilterStart(
									new Date(
										new Date(newRentTimeFilter).setTime(
											newRentTimeFilter.getTime() - minimumHours * 60 * 60 * 1000
										)
									).getTime()
								);
								setRentTimeFilterEnd(
									new Date(
										newRentTimeFilter.setTime(newRentTimeFilter.getTime() + 60000)
									).getTime()
								);
							}}
							value={`${date.getFullYear()}-${(date.getMonth() + 1)
								.toString()
								.padStart(2, 0)}-${date.getDate().toString().padStart(2, 0)}`}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
						&nbsp;
						<Form.Control
							type="time"
							onChange={(e) => {
								const newTime =
									e.target.value ||
									`${new Date().getHours().toString().padStart(2, 0)}:${new Date()
										.getMinutes()
										.toString()
										.padStart(2, 0)}`;
								setTime(newTime);
								const newRentTimeFilter = new Date(
									date.getFullYear(),
									date.getMonth(),
									date.getDate(),
									parseInt(newTime.split(":")[0]),
									parseInt(newTime.split(":")[1])
								);
								setRentTimeFilterStart(
									new Date(
										new Date(newRentTimeFilter).setTime(
											newRentTimeFilter.getTime() - minimumHours * 60 * 60 * 1000
										)
									).getTime()
								);
								setRentTimeFilterEnd(
									new Date(
										newRentTimeFilter.setTime(newRentTimeFilter.getTime() + 60000)
									).getTime()
								);
							}}
							value={time}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
					</div>
				</Card.Body>
				{/* </Tab>
					<Tab eventKey="history" title="Histórico">
						<Card.Body>
							<div className="mb-3">
								<label>
									<input type="radio" name="timeRange" className="form-check-input" />
									&nbsp;&nbsp;Último año
								</label>
							</div>
							<div className="mb-3">
								<label>
									<input type="radio" name="timeRange" className="form-check-input" />
									&nbsp;&nbsp;Último mes
								</label>
							</div>
							<div className="mb-3">
								<label>
									<input type="radio" name="timeRange" className="form-check-input" />
									&nbsp;&nbsp;Última semana
								</label>
							</div>
							<div className="mb-3">
								<label>
									<input type="radio" name="timeRange" className="form-check-input" />
									&nbsp;&nbsp;Hoy
								</label>
							</div>
							<div className="mb-3">
								<hr />
							</div>
							<div className="mb-3">
								<label>
									<input type="checkbox" className="form-check-input" />
									&nbsp;&nbsp;Comparar con anterior
								</label>
							</div>
						</Card.Body>
					</Tab>
					<Tab eventKey="space" title="Por puesto">
						<Card.Body>
							<div className="mb-3">
								<Form.Control placeholder="Número de puesto" />
							</div>
						</Card.Body>
					</Tab>
				</Tabs> */}
			</Card>
			<Card>
				<Card.Header>
					<Card.Title>Ocupación</Card.Title>
					{/* <span style={{ position: "absolute", right: 10, top: 10 }}>
						{date.toLocaleDateString()} {time}
					</span> */}
				</Card.Header>
				<Card.Body>
					{spaces && rents && (
						<Doughnut
							data={{
								labels: ["Disponibles", "Alquilados"],
								datasets: [
									{
										data: [
											spaces.length -
												rents
													.map((r) => r.space)
													.filter((space, index, self) => self.indexOf(space) === index)
													.length,
											rents
												.map((r) => r.space)
												.filter((space, index, self) => self.indexOf(space) === index).length,
										],
										backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(75, 192, 192, 0.2)"],
										borderColor: ["rgba(255, 99, 132, 1)", "rgba(75, 192, 192, 1)"],
										borderWidth: 1,
									},
								],
							}}
						/>
					)}
				</Card.Body>
			</Card>
			{availabilityPerTown && (
				<Card>
					<Card.Header>
						<Card.Title>Disponibilidad por parroquia</Card.Title>
					</Card.Header>
					<Card.Body>
						<Doughnut
							data={{
								labels: Object.keys(availabilityPerTown),
								datasets: [
									{
										data: Object.values(availabilityPerTown).map(
											(town) => town.spaces.length
										),
										backgroundColor: Object.values(availabilityPerTown).map(
											(town) =>
												`rgba(${town.rgb.red}, ${town.rgb.green}, ${town.rgb.blue}, 0.2)`
										),
										borderColor: Object.values(availabilityPerTown).map(
											(town) =>
												`rgba(${town.rgb.red}, ${town.rgb.green}, ${town.rgb.blue}, 1)`
										),
										borderWidth: 1,
									},
								],
							}}
							options={{
								onClick: (event, [{ index }]) => {
									setSelectedBlock(undefined);
									setAvailabilityPerBlock(undefined);
									setAvailabilityPerStreet(undefined);

									setSelectedTown(Object.keys(availabilityPerTown)[index]);

									setAvailabilityPerNeighborhood(
										Object.values(availabilityPerTown)
											[index].spaces.filter(
												(s) =>
													!rents
														.map((r) => r.location)
														.flat()
														.includes(s.id)
											)
											.map((s) => {
												const space = { ...s };
												for (const neighborhood of neighborhoods) {
													if (s.containers.includes(neighborhood.id)) {
														space.neighborhood = neighborhood.name;
													}
												}
												return space;
											})
											.reduce((neighborhoods, space) => {
												if (!neighborhoods[space.neighborhood]) {
													neighborhoods[space.neighborhood] = {
														rgb: {
															red: Math.floor(Math.random() * 200),
															green: Math.floor(Math.random() * 200),
															blue: Math.floor(Math.random() * 200),
														},
														spaces: [space],
													};
												} else neighborhoods[space.neighborhood].spaces.push(space);
												return neighborhoods;
											}, {})
									);
								},
							}}
						/>
					</Card.Body>
				</Card>
			)}
			{availabilityPerNeighborhood && (
				<Card>
					<Card.Header>
						<Card.Title>Disponibilidad en {selectedTown}</Card.Title>
					</Card.Header>
					<Card.Body>
						<Doughnut
							data={{
								labels: Object.keys(availabilityPerNeighborhood),
								datasets: [
									{
										data: Object.values(availabilityPerNeighborhood).map(
											(neighborhood) => neighborhood.spaces.length
										),
										backgroundColor: Object.values(availabilityPerNeighborhood).map(
											(neighborhood) =>
												`rgba(${neighborhood.rgb.red}, ${neighborhood.rgb.green}, ${neighborhood.rgb.blue}, 0.2)`
										),
										borderColor: Object.values(availabilityPerNeighborhood).map(
											(neighborhood) =>
												`rgba(${neighborhood.rgb.red}, ${neighborhood.rgb.green}, ${neighborhood.rgb.blue}, 1)`
										),
										borderWidth: 1,
									},
								],
							}}
							options={{
								onClick: (event, [{ index }]) => {
									setSelectedBlock(undefined);
									setAvailabilityPerBlock(undefined);

									setSelectedNeighborhood(Object.keys(availabilityPerNeighborhood)[index]);

									setAvailabilityPerStreet(
										Object.values(availabilityPerNeighborhood)
											[index].spaces.filter(
												(s) =>
													!rents
														.map((r) => r.location)
														.flat()
														.includes(s.id)
											)
											.map((s) => {
												const space = { ...s };
												for (const street of streets) {
													if (s.containers.includes(street.id)) {
														space.street = street.name;
													}
												}
												return space;
											})
											.reduce((streets, space) => {
												if (!streets[space.street]) {
													streets[space.street] = {
														rgb: {
															red: Math.floor(Math.random() * 200),
															green: Math.floor(Math.random() * 200),
															blue: Math.floor(Math.random() * 200),
														},
														spaces: [space],
													};
												} else streets[space.street].spaces.push(space);
												return streets;
											}, {})
									);
								},
							}}
						/>
					</Card.Body>
				</Card>
			)}
			{availabilityPerStreet && (
				<Card>
					<Card.Header>
						<Card.Title>Disponibilidad en {selectedNeighborhood}</Card.Title>
					</Card.Header>
					<Card.Body>
						<Doughnut
							data={{
								labels: Object.keys(availabilityPerStreet),
								datasets: [
									{
										data: Object.values(availabilityPerStreet).map(
											(street) => street.spaces.length
										),
										backgroundColor: Object.values(availabilityPerStreet).map(
											(street) =>
												`rgba(${street.rgb.red}, ${street.rgb.green}, ${street.rgb.blue}, 0.2)`
										),
										borderColor: Object.values(availabilityPerStreet).map(
											(street) =>
												`rgba(${street.rgb.red}, ${street.rgb.green}, ${street.rgb.blue}, 1)`
										),
										borderWidth: 1,
									},
								],
							}}
							options={{
								onClick: (event, [{ index }]) => {
									setSelectedBlock(undefined);

									setSelectedStreet(Object.keys(availabilityPerStreet)[index]);

									setAvailabilityPerBlock(
										Object.values(availabilityPerStreet)
											[index].spaces.filter(
												(s) =>
													!rents
														.map((r) => r.location)
														.flat()
														.includes(s.id)
											)
											.map((s) => {
												const space = { ...s };
												for (const block of blocks) {
													if (s.containers.includes(block.id)) {
														space.block = block;
													}
												}
												return space;
											})
											.reduce((blocks, space) => {
												if (!blocks[space.block.name]) {
													blocks[space.block.name] = {
														rgb: {
															red: Math.floor(Math.random() * 200),
															green: Math.floor(Math.random() * 200),
															blue: Math.floor(Math.random() * 200),
														},
														spaces: [space],
														data: space.block,
													};
												} else blocks[space.block.name].spaces.push(space);
												return blocks;
											}, {})
									);
								},
							}}
						/>
					</Card.Body>
				</Card>
			)}
			{availabilityPerBlock && (
				<Card>
					<Card.Header>
						<Card.Title>Disponibilidad en {selectedStreet}</Card.Title>
					</Card.Header>
					<Card.Body>
						<Doughnut
							data={{
								labels: Object.keys(availabilityPerBlock),
								datasets: [
									{
										data: Object.values(availabilityPerBlock).map(
											(block) => block.spaces.length
										),
										backgroundColor: Object.values(availabilityPerBlock).map(
											(block) =>
												`rgba(${block.rgb.red}, ${block.rgb.green}, ${block.rgb.blue}, 0.2)`
										),
										borderColor: Object.values(availabilityPerBlock).map(
											(block) =>
												`rgba(${block.rgb.red}, ${block.rgb.green}, ${block.rgb.blue}, 1)`
										),
										borderWidth: 1,
									},
								],
							}}
							options={{
								onClick: (event, [{ index }]) =>
									setSelectedBlock(Object.values(availabilityPerBlock)[index]),
							}}
						/>
					</Card.Body>
				</Card>
			)}
			{selectedBlock && (
				<Card>
					<Card.Header>
						<Card.Title>
							Disponibilidad en {selectedStreet} {selectedBlock.data.name}
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<Block
							block={selectedBlock.data}
							spaces={spaces
								.filter((space) => space.containers.includes(selectedBlock.data.id))
								.map((space) => ({
									...space,
									available: selectedBlock.spaces
										.map((availableSpace) => availableSpace.id)
										.includes(space.id),
								}))}
						></Block>
					</Card.Body>
				</Card>
			)}
		</div>
	);
};

export default React.memo(Rents);
