import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import "bootstrap-icons/font/bootstrap-icons.css";
import './App.scss';
import './App.css';
import { setLabels } from "./utilities/utilities";
import areaTypes from './components/spaces/areaTypes'
import Backdrop from "./Backdrop";
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import SignIn from "./components/signin/SignIn";
import Collection from "./components/collection/Collection";
import Rents from "./components/rent/Rents";
import Rent from "./components/rent/Rent";
import Areas from "./components/spaces/Areas";

const App = () =>
{
	const minimumHours = 0.5;
	// The minimumHours cannot be greater than 1
	const maximumHours = 8;

	const [authorized, setAuthorized] = useState(false);
	const [contextualMenu, setContextualMenu] = useState();
	const [modal, setModal] = useState();
	const auth = getAuth();
	const db = getFirestore();
	auth.onAuthStateChanged((authData) => authData?.emailVerified && onSnapshot(doc(db, `users/${authData.uid}`), (doc) => setAuthorized(!!doc.data().authorized)));

	const modalForm = useRef();
	useEffect(() => modal && (modal.show ?? true) && setLabels(modalForm.current), [modal]);

	return <div className="App">
		{process.env.REACT_APP_ENV !== "dev" && <Backdrop />}
		<BrowserRouter>
			<Navbar auth={auth} authorized={authorized} setAuthorized={setAuthorized} contextualMenu={contextualMenu} setContextualMenu={setContextualMenu} />
			<Routes>
				<Route path="/" element={
					authorized
						? <Rent
							areaTypes={areaTypes}
							setModal={setModal}
							auth={auth}
							minimumHours={minimumHours}
							maximumHours={maximumHours} />
						: <SignIn auth={auth} setAuthorized={setAuthorized} />
				} />
				{authorized && <>
					{/* <Route path="/rent" element={<Rent areaTypes={areaTypes} setModal={setModal} auth={auth} />} /> */}
					<Route path="/rents" element={<Rents minimumHours={minimumHours} />} />
					<Route path="/collection" element={<Collection />} />
					<Route path="/areas" element={<Areas areaTypes={areaTypes} setContextualMenu={setContextualMenu} setModal={setModal} />} />
				</>}
			</Routes>
			{/* <Footer /> */}
		</BrowserRouter>

		{modal &&
			<Modal show={modal.show ?? true} onHide={() => setModal({ ...modal, show: false })} centered onShow={() => modal.cancelFocusOnShow || document.querySelector(".modal [class*='form-']")?.focus()}>
				{modal.title &&
					<Modal.Header closeButton><Modal.Title>{modal.title}</Modal.Title></Modal.Header>
				}
				<Form ref={modalForm} onSubmit={async (e) =>
				{
					e.preventDefault();
					if (modal.onsubmit) await modal.onsubmit();
					setModal(undefined);
				}}>
					{modal.controls &&
						<Modal.Body>
							{modal.controls.map((field, index) => <Form.Group key={index} className="mb-3">{field}</Form.Group>)}
						</Modal.Body>
					}
					<Modal.Footer>
						{modal.cancellable !== false && <Button type="button" variant="secondary" onClick={() => setModal({ ...modal, show: false })}>Cancelar</Button>}
						<Button type="submit" variant="primary">Aceptar</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		}
	</div>
}
export default React.memo(App);
