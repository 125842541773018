import React, { useEffect, useRef, useState } from "react";
import { Card, Form, Button, InputGroup, FormControl, Tabs, Tab, FormLabel, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { orderBy, groupBy, maxBy } from "lodash";
import { setLabels } from "../../utilities/utilities";

const Rent = ({ areaTypes, setModal, auth, minimumHours, maximumHours }) => {

	const [hours, setHours] = useState(minimumHours);
	const [spaceName, setSpaceName] = useState("");
	const [validatingData, setValidatingData] = useState();

	const signInForm = useRef();

	const firestore = useFirestore();

	useFirestoreConnect([
		{
			collection: "rents",
			where: [["user", "==", auth.currentUser.email]],
		},
	]);
	const rents = useSelector(({ firestore: { ordered } }) => ordered.rents);

	useEffect(() => setLabels(signInForm.current), []);

	return (
		<div style={{ maxWidth: 400 }}>
			<Card>
				<Tabs>
					<Tab eventKey="rent" title="Alquilar puesto">
						<Card.Body>
							<Form
								ref={signInForm}
								onSubmit={async (e) => {
									e.preventDefault();
									setValidatingData(true);
									const spaceRef = (await firestore.collection("areas").where("type", "==", "space").where("name", "==", spaceName).get()).docs?.[0];
									const space = { ...spaceRef.data(), id: spaceRef.id };
									const areas = [];
									for (const areaId of space.containers) {
										const areaData = (await firestore.doc(`areas/${areaId}`).get()).data();
										areas.push({
											...areaData,
											type: { ...areaTypes.find((t) => t.id === areaData.type), index: areaTypes.findIndex((t) => t.id === areaData.type) },
										});
									}
									const location = space.location || areas.sort((a, b) => (a.type.index > b.type.index ? -1 : 1))[0]?.location;
									if (space) {
										setModal({
											title: `CONFIRMAR PAGO`,
											controls: [
												<FormLabel>
													<strong>Puesto</strong>: <strong style={{ color: "red" }}>{space.name}</strong>
													<br />
													<strong>Alquilado hasta</strong>:{" "}
													<strong style={{ color: "red" }}>
														{new Date(new Date().setTime(new Date().getTime() + hours * 60 * 60 * 1000)).toLocaleTimeString("en-US", {
															hour: "2-digit",
															minute: "2-digit",
															hour12: true,
														})}
													</strong>
													<br />
													<strong>Total</strong>:{" "}
													<strong style={{ color: "red" }}>
														{new Intl.NumberFormat(undefined, {
															style: "currency",
															currency: "USD",
														}).format((hours >= maximumHours ? space.dailyFee : hours * space.hourlyFee).toFixed(2))}
													</strong>
													<br />
													<strong>Ubicación</strong>:{" "}
													{areas
														.filter((area) => area.type.index >= 4)
														.sort((a, b) => (a.type.index < b.type.index ? -1 : 1))
														.reduce((location, area) => {
															location.push(area.name.trim());
															return location;
														}, [])
														.join(", ")}
												</FormLabel>,
												<>
													{location && (
														<iframe
															width="100%"
															height="300"
															style={{ border: "1px solid lightgray", marginBottom: 0 }}
															loading="lazy"
															allowFullScreen
															src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDzuEC6j6IMqHvCkmoD6bjMpaME4PiGmZI&q=${escape(location)}`}
														></iframe>
													)}
												</>,
											],
											onsubmit: async () => {
												for (let rentHours = 0; rentHours <= hours - minimumHours; rentHours += minimumHours) {
													await firestore.collection("rents").add({
														location: [...space.containers, space.id],
														at: new Date(new Date().setTime(new Date().getTime() + rentHours * 60 * 60 * 1000)),
														rent: hours >= maximumHours ? (space.dailyFee / hours) * minimumHours : space.hourlyFee * minimumHours,
														user: auth.currentUser.email,
														space: space.name,
														address: areas
															.filter((area) => area.type.index >= 4)
															.sort((a, b) => (a.type.index < b.type.index ? -1 : 1))
															.reduce((location, area) => {
																location.push(area.name.trim());
																return location;
															}, [])
															.join(", "),
													});
												}
												setSpaceName("");
												setHours(minimumHours);
											},
										});
									} else {
										alert("No existe un puesto con este identificador, por favor verifique el número de puesto.");
										signInForm.current.querySelector("#spaceName").focus();
									}
									setValidatingData(false);
								}}
							>
								<Card.Title>Puesto número:</Card.Title>
								<InputGroup className="mb-3">
									<FormControl
										id="spaceName"
										type="text"
										pattern="[0-9]*"
										inputMode="tel"
										placeholder="0000"
										className="hidePlaceholder"
										autoComplete="off"
										required
										minLength="4"
										maxLength="4"
										onChange={(e) => setSpaceName(e.target.value)}
										value={spaceName}
										style={{ fontSize: 40, letterSpacing: 34, textAlign: "center", textIndent: 34, caretColor: !spaceName ? "transparent" : "black" }}
										disabled={validatingData}
									/>
									{/* <InputGroup.Text id="basic-addon1">
										<i className="bi bi-camera"></i>
									</InputGroup.Text> */}
								</InputGroup>
								<br />
								{/* <Card.Title>Número de horas</Card.Title> */}
								<InputGroup className="mb-3">
									<div style={{ margin: "auto", width: "100%", flexWrap: "nowrap" }} className="row">
										<div className="col" style={{ display: "flex", justifyContent: "center", padding: 0 }}>
											<Button onClick={() => hours > minimumHours && setHours(hours - minimumHours)} style={{ width: "100%" }} disabled={validatingData}>
												-
											</Button>
										</div>
										<div className="col" style={{ whiteSpace: "nowrap", minWidth: 150, textAlign: "center" }}>
											<span style={{ fontSize: 25 }}>{hours < maximumHours ? `${hours.toLocaleString(undefined, { minimumFractionDigits: 1 })} hora(s)` : "1 día"} </span>
										</div>
										<div className="col" style={{ display: "flex", justifyContent: "center", padding: 0 }}>
											<Button onClick={() => hours < maximumHours && setHours(hours + minimumHours)} style={{ width: "100%" }} disabled={validatingData}>
												+
											</Button>
										</div>
									</div>
								</InputGroup>
								<br />
								<div style={{ display: "flex", justifyContent: "center" }}>
									{validatingData ? (
										<div>
											<div style={{ display: "flex", justifyContent: "center" }}>
												<Spinner animation="border" size="sm" style={{ margin: "auto" }} />
											</div>
											<br />
											Validando datos del pago...
										</div>
									) : (
										<Button type="submit" variant="primary" style={{ float: "right", width: "100%" }} disabled={validatingData}>
											Pagar
										</Button>
									)}
								</div>
								<br />
							</Form>
						</Card.Body>
					</Tab>
					{/* <Tab eventKey="dfgdgf" title="Pagar multa"></Tab> */}
				</Tabs>
			</Card>

			{rents &&
				orderBy(
					Object.values(groupBy(rents, "space"))
						.map((rent) => maxBy(rent, "at"))
						.filter((rent) => new Date(rent.at.toDate().setTime(rent.at.toDate().getTime() + minimumHours * 60 * 60 * 1000)) > new Date()),
					"at"
				).map((rent, index) => (
					<Card key={index}>
						<Card.Header>
							<Card.Title>
								Has alquilado el puesto: <strong style={{ color: "red" }}>{rent.space}</strong>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<Card.Text>
								<strong>Alquilado hasta</strong>:{" "}
								<strong style={{ color: "red" }}>
									{new Date(rent.at.toDate().setTime(rent.at.toDate().getTime() + minimumHours * 60 * 60 * 1000)).toLocaleTimeString("en-US", {
										hour: "2-digit",
										minute: "2-digit",
										hour12: true,
									})}
								</strong>
							</Card.Text>
							<Card.Text>
								<strong>Ubicación</strong>: {rent.address}
							</Card.Text>
						</Card.Body>
					</Card>
				))}
		</div>
	);
};

export default React.memo(Rent);
