import React, { useState } from "react";
import { Card, Form, ProgressBar } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, BarElement, Tooltip, Legend, CategoryScale, LinearScale);
// ChartJS.defaults.plugins.legend.display = false;

const Collection = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [startTime, setStartTime] = useState("00:00");
	const [endDate, setEndDate] = useState(new Date());
	const [endTime, setEndTime] = useState(
		`${new Date().getHours().toString().padStart(2, 0)}:${new Date().getMinutes().toString().padStart(2, 0)}`
	);

	// useFirestoreConnect([
	// 	{
	// 		collection: "rents",
	// 		where: [
	// 			["at", ">=", new Date(rentTimeFilterStart)],
	// 			["at", "<=", new Date(rentTimeFilterEnd)],
	// 		],
	// 	},
	// ]);
	// const rents = useSelector(({ firestore: { ordered } }) => ordered.rents);

	return (
		<div style={{ maxWidth: 400 }}>
			{/* <ProgressBar now={6}  label="asdasdasdasdasdasdasdasdasdasdasdasdasdasd"/> */}
			<Card>
				<Card.Header>
					<Card.Title>Consultar Recaudación</Card.Title>
				</Card.Header>
				<Card.Body>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<label>Desde:</label>
						<Form.Control
							type="date"
							onChange={(e) => {
								const dateParts =
									e.target.value && e.target.value.split("-").map((p) => parseInt(p));
								const newDate = dateParts
									? new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
									: new Date();
								setStartDate(newDate);
							}}
							value={`${startDate.getFullYear()}-${(startDate.getMonth() + 1)
								.toString()
								.padStart(2, 0)}-${startDate.getDate().toString().padStart(2, 0)}`}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
						&nbsp;
						<Form.Control
							type="time"
							onChange={(e) => {
								const newTime =
									e.target.value ||
									`${new Date().getHours().toString().padStart(2, 0)}:${new Date()
										.getMinutes()
										.toString()
										.padStart(2, 0)}`;
								setStartTime(newTime);
							}}
							value={startTime}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<label>Hasta:</label>
						<Form.Control
							type="date"
							onChange={(e) => {
								const dateParts =
									e.target.value && e.target.value.split("-").map((p) => parseInt(p));
								const newDate = dateParts
									? new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
									: new Date();
								setEndDate(newDate);
							}}
							value={`${endDate.getFullYear()}-${(endDate.getMonth() + 1)
								.toString()
								.padStart(2, 0)}-${endDate.getDate().toString().padStart(2, 0)}`}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
						&nbsp;
						<Form.Control
							type="time"
							onChange={(e) => {
								const newTime =
									e.target.value ||
									`${new Date().getHours().toString().padStart(2, 0)}:${new Date()
										.getMinutes()
										.toString()
										.padStart(2, 0)}`;
								setEndTime(newTime);
							}}
							value={endTime}
							style={{ border: 0, width: "50%", textAlign: "right" }}
							required
						/>
					</div>
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>
					<Card.Title>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<span>Total Recaudado</span>
							<span>$74.584</span>
						</div>
					</Card.Title>
				</Card.Header>
				{/* <Card.Body>
					<Bar
						data={{
							labels: [
								"2022-01",
								"2022-02",
								"2022-03",
								"2022-04",
								"2022-05",
								"2022-06",
								"2022-07",
								"2022-08",
								"2022-09",
								"2022-10",
								"2022-11",
								"2022-12",
							],
							datasets: [
								{
									label: "$",
									data: [
										12000, 19000, 3000, 5000, 2000, 3000, 20000, 3000, 5000, 6000, 2000, 1000,
									],
									backgroundColor: [
										"rgba(255, 99, 132, 0.2)",
										"rgba(54, 162, 235, 0.2)",
										"rgba(255, 206, 86, 0.2)",
										"rgba(75, 192, 192, 0.2)",
										"rgba(153, 102, 255, 0.2)",
										"rgba(255, 159, 64, 0.2)",
										"rgba(255, 99, 132, 0.2)",
										"rgba(54, 162, 235, 0.2)",
										"rgba(255, 206, 86, 0.2)",
										"rgba(75, 192, 192, 0.2)",
										"rgba(153, 102, 255, 0.2)",
										"rgba(255, 159, 64, 0.2)",
									],
									borderColor: [
										"rgba(255,99,132,1)",
										"rgba(54, 162, 235, 1)",
										"rgba(255, 206, 86, 1)",
										"rgba(75, 192, 192, 1)",
										"rgba(153, 102, 255, 1)",
										"rgba(255, 159, 64, 1)",
										"rgba(255,99,132,1)",
										"rgba(54, 162, 235, 1)",
										"rgba(255, 206, 86, 1)",
										"rgba(75, 192, 192, 1)",
										"rgba(153, 102, 255, 1)",
										"rgba(255, 159, 64, 1)",
									],
									borderWidth: 1,
								},
							],
						}}
						options={{
							plugins: {
								legend: {
									display: false,
								},
							},
							scales: {
								y: {
									ticks: {
										// Include a dollar sign in the ticks
										callback: function (value, index, ticks) {
											return "$" + value;
										},
									},
								},
							},
						}}
					/>
				</Card.Body> */}
			</Card>
			{/* <Card>
			<Card.Header>
				<Card.Title>Recaudacion por parroquia (Ene 22)</Card.Title>
			</Card.Header>
			<Card.Body>
				<Doughnut
					data={{
						labels: ["Catia La Mar", "Maiquetía", "Macuto", "Caraballeda", "Naiguata"],
						datasets: [
							{
								data: [190, 120, 50, 30, 20],
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(255, 206, 86, 0.2)",
									"rgba(75, 192, 192, 0.2)",
									"rgba(153, 102, 255, 0.2)",
								],
								borderColor: [
									"rgba(255, 99, 132, 1)",
									"rgba(54, 162, 235, 1)",
									"rgba(255, 206, 86, 1)",
									"rgba(75, 192, 192, 1)",
									"rgba(153, 102, 255, 1)",
								],
								borderWidth: 1,
							},
						],
					}}
				/>
			</Card.Body>
		</Card>
		<Card>
			<Card.Header>
				<Card.Title>Recaudacion por hora (Ene 22)</Card.Title>
			</Card.Header>
			<Card.Body>
				<Doughnut
					data={{
						labels: ["8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM"],
						datasets: [
							{
								data: [12, 19, 3, 5, 2, 3],
								backgroundColor: [
									"rgba(255, 99, 132, 0.2)",
									"rgba(54, 162, 235, 0.2)",
									"rgba(255, 206, 86, 0.2)",
									"rgba(75, 192, 192, 0.2)",
									"rgba(153, 102, 255, 0.2)",
									"rgba(255, 159, 64, 0.2)",
								],
								borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
								borderWidth: 1,
							},
						],
					}}
				/>
			</Card.Body>
		</Card> */}
		</div>
	);
};

export default React.memo(Collection);
