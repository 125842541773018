import React from 'react';
import ReactDOM from 'react-dom';
import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from './firebase/config';
import 'firebase/compat/firestore';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize react-redux-firebase
// Add firestore to reducers
const rootReducer = combineReducers({
	firestore: firestoreReducer
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

// react-redux-firebase config
const rrfConfig = {
	useFirestoreForProfile: false
};
const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance
};

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<App />
			</ReactReduxFirebaseProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
