import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getFirestore, onSnapshot, collection, where, query, writeBatch } from "firebase/firestore";
import { Form, Card, ListGroup, Breadcrumb, Dropdown, FormLabel, InputGroup, FormControl, Modal, Toast } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Block from "./Block";

const Areas = ({ areaTypes, setContextualMenu, setModal }) => {
	const [areas, setAreas] = useState();
	const [currentArea, setCurrentArea] = useState();
	const [showHoursModal, setShowHoursModal] = useState(false);
	const [showHowToAddHours, setShowHowToAddHours] = useState(true);
	const [showHowToModifyHours, setShowHowToModifyHours] = useState(true);
	const [showHowToDeleteHours, setShowHowToDeleteHours] = useState(true);

	const db = getFirestore();
	const batch = writeBatch(db);
	const firestore = useFirestore();
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	useEffect(() => {
		const unsubscribe = onSnapshot(query(collection(db, "areas"), where("deleted", "==", false)), (areas) =>
			setAreas(
				areas.docs.map((area) => {
					const areaData = area.data();
					const index = areaTypes.findIndex((t) => t.id === areaData.type);
					const type = areaTypes[index];
					return {
						...areaData,
						id: area.id,
						type: {
							...type,
							index,
							subcollections: type.subcollections?.map((subcollection) => areaTypes[areaTypes.findIndex((t) => t.collection === subcollection)]),
						},
					};
				})
			)
		);
		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (areas)
			if (currentArea) setCurrentArea(areas.find((area) => area.id === currentArea.id));
			else setCurrentArea(areas.find((area) => area.type.id === "municipality"));
	}, [areas]);

	useEffect(() => {
		if (currentArea) {
			setContextualMenu({
				items: [
					<Dropdown.Item
						href="#contextualMenu/setAreaName"
						onClick={() => {
							switch (currentArea.type.id) {
								case "block":
									setModal({
										title: `Editar ${currentArea.type.name} ${currentArea.streetName} ${currentArea.name}`,
										controls: generateBlockControls(
											currentArea,
											areas.filter(
												(area) =>
													area.type.id ===
														areas.filter((area) => currentArea?.containers.includes(area.id))?.sort((a, b) => (a.type.index > b.type.index ? -1 : 1))[0].type.id &&
													area.containers.includes(
														areas.filter((area) => currentArea?.containers.includes(area.id)).sort((a, b) => (a.type.index > b.type.index ? -1 : 1))?.[1]?.id
													) &&
													area.id !== areas.filter((area) => currentArea?.containers.includes(area.id))?.sort((a, b) => (a.type.index > b.type.index ? -1 : 1))[0].id
											)
										),
										onsubmit: async () => {
											const intersection1 = blindAlley.concat(areas).find((area) => area.id === document.getElementById("intersection1").value);
											const intersection2 = blindAlley.concat(areas).find((area) => area.id === document.getElementById("intersection2").value);
											await firestore.update(`areas/${currentArea.id}`, {
												name: `Entre ${intersection1.name} y ${intersection2.name}`,
												location: document.getElementById("areaLocation").value,
												hourlyFee: document.getElementById("hourlyFee").value ? parseFloat(document.getElementById("hourlyFee").value) : 0,
												dailyFee: document.getElementById("dailyFee").value ? parseFloat(document.getElementById("dailyFee").value) : 0,
												intersection1: { id: intersection1.id, name: intersection1.name },
												intersection2: { id: intersection2.id, name: intersection2.name },
											});
											await updateChildrenFees();
										},
									});
									break;

								default:
									setModal({
										title: `Editar ${currentArea.type.name} ${currentArea.name}`,
										controls: generateAreaControls(currentArea),
										onsubmit: async () => {
											await firestore.update(`areas/${currentArea.id}`, {
												name: document.getElementById("areaName").value,
												location: document.getElementById("areaLocation").value,
												hourlyFee: document.getElementById("hourlyFee").value ? parseFloat(document.getElementById("hourlyFee").value) : 0,
												dailyFee: document.getElementById("dailyFee").value ? parseFloat(document.getElementById("dailyFee").value) : 0,
											});
											await updateChildrenFees();
										},
									});
									break;
							}
						}}
					>{`Editar ${currentArea.type.name} ${currentArea.name.substring(0, 20)}...`}</Dropdown.Item>,
					<Dropdown.Item href="#contextualMenu/setHours" onClick={() => setShowHoursModal(true)}>{`Horario de ${currentArea.type.name} ${currentArea.name.substring(
						0,
						20
					)}...`}</Dropdown.Item>,
					<Dropdown.Divider></Dropdown.Divider>,
					<Dropdown.Item
						href="#contextualMenu/deleteArea"
						onClick={() => {
							setModal({
								title: `¿Esta seguro que desea eliminar ${currentArea.type.name} ${currentArea.name}?`,
								controls: [<FormLabel>{`Al eliminar ${currentArea.type.name} ${currentArea.name}, todos los puestos en esta area ya no estaran disponibles.`}</FormLabel>],
								onsubmit: async () => {
									await firestore.update(`areas/${currentArea.id}`, { deleted: true });
									const childrenAreas = areas.filter((area) => area.containers.includes(currentArea.id));
									for (const area of childrenAreas) {
										batch.update(firestore.doc(`areas/${area.id}`), { deleted: true });
									}
									await batch.commit();
									setCurrentArea(areas.filter((area) => currentArea.containers.includes(area.id)).sort((a, b) => (a.type.index > b.type.index ? -1 : 1))?.[0]);
								},
							});
						}}
					>{`Eliminar ${currentArea.type.name} ${currentArea.name.substring(0, 20)}...`}</Dropdown.Item>,
				],
			});
		}
	}, [currentArea]);

	const updateChildrenFees = async () => {
		const childrenAreas = areas.filter((area) => area.containers.includes(currentArea.id));
		if (
			childrenAreas &&
			(currentArea.hourlyFee != document.getElementById("hourlyFee").value || currentArea.dailyFee != document.getElementById("dailyFee").value) &&
			window.confirm(`¿Desea asignar la nueva tarifa a todos los puestos en ${currentArea.type.name} ${currentArea.name}?`)
		) {
			if (currentArea.hourlyFee != document.getElementById("hourlyFee").value) {
				for (const area of childrenAreas) {
					batch.update(firestore.doc(`areas/${area.id}`), { hourlyFee: parseFloat(document.getElementById("hourlyFee").value || 0) });
				}
			}
			if (currentArea.dailyFee != document.getElementById("dailyFee").value) {
				for (const area of childrenAreas) {
					batch.update(firestore.doc(`areas/${area.id}`), { dailyFee: parseFloat(document.getElementById("dailyFee").value || 0) });
				}
			}
			await batch.commit();
		}
	};

	const updateChildrenHours = async (currentArea) => {
		const childrenAreas = areas.filter((area) => area.containers.includes(currentArea.id));
		if (childrenAreas && Object.keys(currentArea.hours).length && window.confirm(`¿Desea asignar este horario a todos los puestos en ${currentArea.type.name} ${currentArea.name}?`)) {
			for (const area of childrenAreas) {
				batch.update(firestore.doc(`areas/${area.id}`), { hours: currentArea.hours });
			}
			await batch.commit();
		}
	};

	const generateAreaControls = (area) => [<Form.Control type="text" id="areaName" placeholder="Nombre" maxLength="100" required defaultValue={area?.name} />].concat(generateCommonControls(area));

	const blindAlley = [{ id: "end", name: "Calle ciega" }];

	const generateBlockControls = (area, streets) => {
		streets = blindAlley.concat(streets.map((street) => ({ id: street.id, name: street.name })).sort((a, b) => (a.name < b.name ? -1 : 1)));
		return [
			<Form.Select id="intersection1" required defaultValue={area?.intersection1.id}>
				<option value="">Primera intersección</option>
				<optgroup>
					{streets.map((street, index) => (
						<option key={index} value={street.id}>
							{street.name}
						</option>
					))}
				</optgroup>
			</Form.Select>,
			<Form.Select id="intersection2" required defaultValue={area?.intersection2.id}>
				<option value="">Segunda intersección</option>
				<optgroup>
					{streets.map((street, index) => (
						<option key={index} value={street.id}>
							{street.name}
						</option>
					))}
				</optgroup>
			</Form.Select>,
		].concat(generateCommonControls(area));
	};

	const generateCommonControls = (area) => [
		<Form.Control type="text" id="areaLocation" placeholder="Buscar en Google Maps" maxLength="2000" defaultValue={area?.location} />,
		<InputGroup className="mb-3">
			<InputGroup.Text id="basic-addon1">$</InputGroup.Text>
			<FormControl type="number" id="hourlyFee" placeholder="Tarifa por hora" min={0} step="0.1" defaultValue={area?.hourlyFee ?? (!area && currentArea?.hourlyFee)} />
		</InputGroup>,
		<InputGroup className="mb-3">
			<InputGroup.Text id="basic-addon1">$</InputGroup.Text>
			<FormControl type="number" id="dailyFee" placeholder="Tarifa por día" min={0} step="0.1" defaultValue={area?.dailyFee ?? (!area && currentArea?.dailyFee)} />
		</InputGroup>,
	];

	return (
		<div style={{ maxWidth: 400 }}>
			{currentArea && (
				<Breadcrumb>
					{areas
						?.filter((area) => area.id === currentArea.id || (area.type.index >= 3 && currentArea.containers.includes(area.id)))
						.sort((a, b) => (a.type.index < b.type.index ? -1 : 1))
						.map((area, index) => (
							<Breadcrumb.Item key={index} onClick={() => setCurrentArea(area)} active={area.id === currentArea.id}>
								{area.type.id === "space" ? "Puesto" : ""} {area.name}
							</Breadcrumb.Item>
						))}
				</Breadcrumb>
			)}
			{(currentArea?.type.subcollections ?? !currentArea) &&
				[]
					.concat(currentArea?.type.subcollections ?? areaTypes[0])
					.map((type) => ({ ...type, index: areaTypes.findIndex((t) => t.id === type.id) }))
					.map((type, index) => (
						<div key={index}>
							<h1>{type.collection}</h1>
							<Card>
								{currentArea?.type.id === "block" && type.id === "space" ? (
									<Block block={currentArea} generateAreaControls={generateAreaControls} setModal={setModal} spaces={areas.filter((area) => area.type.id === "space" && area.containers.includes(currentArea.id))} setCurrentArea={setCurrentArea}></Block>
								) : (
									<ListGroup variant="flush" activeKey={`#add${type.name}`}>
										{areas
											?.filter((area) => area.type.id === type.id && (!currentArea || area.containers.includes(currentArea.id)))
											.sort((a, b) => (a.name < b.name ? -1 : 1))
											.map((area, index) => (
												<ListGroup.Item key={index} href={`#area${area.id}`} action onClick={() => setCurrentArea(area)}>
													{area.name}
												</ListGroup.Item>
											))}
										<ListGroup.Item
											href={`#add${type.name}`}
											action
											onClick={() => {
												switch (type.id) {
													case "block":
														setModal({
															title: `Nueva ${type.name}`,
															controls: generateBlockControls(
																null,
																areas.filter(
																	(area) =>
																		area.type.id === currentArea.type.id &&
																		area.containers.includes(
																			areas.filter((area) => currentArea.containers.includes(area.id)).sort((a, b) => (a.type.index > b.type.index ? -1 : 1))?.[0]
																				?.id
																		) &&
																		area.id !== currentArea.id
																)
															),
															onsubmit: async () => {
																const intersection1 = areas.find((area) => area.id === document.getElementById("intersection1").value);
																const intersection2 = areas.find((area) => area.id === document.getElementById("intersection2").value);
																await firestore.collection("areas").add({
																	name: `Entre ${intersection1.name} y ${intersection2.name}`,
																	location: document.getElementById("areaLocation").value,
																	type: type.id,
																	containers: currentArea ? [...currentArea.containers].concat(currentArea.id) : [],
																	deleted: false,
																	active: true,
																	creationDate: new Date(),
																	hourlyFee: document.getElementById("hourlyFee").value ? parseFloat(document.getElementById("hourlyFee").value) : 0,
																	dailyFee: document.getElementById("dailyFee").value ? parseFloat(document.getElementById("dailyFee").value) : 0,
																	hours: currentArea?.hours || null,
																	intersection1: { id: intersection1.id, name: intersection1.name },
																	intersection2: { id: intersection2.id, name: intersection2.name },
																	streetName: currentArea.name,
																});
															},
														});
														break;

													default:
														setModal({
															title: `Nueva ${type.name}`,
															controls: generateAreaControls(),
															onsubmit: async () => {
																await firestore.collection("areas").add({
																	name: document.getElementById("areaName").value,
																	location:
																		document.getElementById("areaLocation").value ||
																		(type.index <= 6
																			? `${document.getElementById("areaName").value}, ${areas
																					.filter((area) => area.id === currentArea.id || currentArea?.containers.includes(area.id))
																					?.sort((a, b) => (a.type.index > b.type.index ? -1 : 1))
																					.map((area) => area.name)
																					// Utilizar la palabra "Vargas" como estado y ciudad o dejar este filtro porque La Guaira no esta actualizado en Google Maps
																					// .filter((area, index, array) => index === 0 || (index >= 3 && index > array.length - 2))
																					.join(", ")}`
																			: null),
																	type: type.id,
																	containers: currentArea ? [...currentArea.containers].concat(currentArea.id) : [],
																	deleted: false,
																	active: true,
																	creationDate: new Date(),
																	hourlyFee: document.getElementById("hourlyFee").value ? parseFloat(document.getElementById("hourlyFee").value) : 0,
																	dailyFee: document.getElementById("dailyFee").value ? parseFloat(document.getElementById("dailyFee").value) : 0,
																	hours: currentArea?.hours || null,
																});
															},
														});
														break;
												}
											}}
											className="d-flex justify-content-between"
										>
											<div>Agregar {type.name}</div>
											<i className="bi bi-plus-circle" style={{ color: "white" }}></i>
										</ListGroup.Item>
									</ListGroup>
								)}
							</Card>
						</div>
					))}
			{currentArea?.location && (
				<iframe
					width="360"
					height="360"
					className="card"
					style={{ border: 0, marginBottom: 0 }}
					loading="lazy"
					allowFullScreen
					src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDzuEC6j6IMqHvCkmoD6bjMpaME4PiGmZI&q=${escape(currentArea.location)}`}
				></iframe>
			)}
			<Modal
				show={showHoursModal}
				onHide={async () => {
					await updateChildrenHours(currentArea);
					setShowHoursModal(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Horario de {currentArea?.type.name} {currentArea?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isTabletOrMobile && (
						<div>
							<Toast show={showHowToAddHours} onClose={() => setShowHowToAddHours(!showHowToAddHours)} bg="info" style={{ marginBottom: 20 }}>
								<Toast.Header>
									<i className="bi bi-info-circle-fill"></i>&nbsp;
									<strong className="me-auto">Para agregar bloques de horas</strong>
									{/* <small>11 mins ago</small> */}
								</Toast.Header>
								<Toast.Body>Mantenga presionado durante 3 segundos en la hora de inicio, luego arrastre y suelte en la hora de finalización</Toast.Body>
							</Toast>
							<Toast show={showHowToModifyHours} onClose={() => setShowHowToModifyHours(!showHowToModifyHours)} bg="info" style={{ marginBottom: 20 }}>
								<Toast.Header>
									<i className="bi bi-info-circle-fill"></i>&nbsp;
									<strong className="me-auto">Para modificar bloques de horas</strong>
									{/* <small>11 mins ago</small> */}
								</Toast.Header>
								<Toast.Body>Arrastre el bloque de horas o estirelo desde el borde inferior</Toast.Body>
							</Toast>
							<Toast show={showHowToDeleteHours} onClose={() => setShowHowToDeleteHours(!showHowToDeleteHours)} bg="info" style={{ marginBottom: 20 }}>
								<Toast.Header>
									<i className="bi bi-info-circle-fill"></i>&nbsp;
									<strong className="me-auto">Para eliminar bloques de horas</strong>
									{/* <small>11 mins ago</small> */}
								</Toast.Header>
								<Toast.Body>Haga clic en un bloque de horas y confirme la eliminación</Toast.Body>
							</Toast>
						</div>
					)}
					<div className="catalogCalendar" style={{ height: "70vh" }}>
						<FullCalendar
							plugins={[timeGridPlugin, interactionPlugin]}
							headerToolbar={false}
							initialView="timeGridWeek"
							allDaySlot={false}
							dayHeaderFormat={{ weekday: "short" }}
							initialDate="2021-01-01"
							editable={true}
							selectable={true}
							slotDuration="00:30:00"
							initialEvents={currentArea?.hours ? Object.values(currentArea.hours) : []}
							height="100%"
							locale="es-VE"
							select={(selectInfo) => {
								let title = " "; //prompt('Enter a title for the block of hours.')
								let calendarApi = selectInfo.view.calendar;
								calendarApi.unselect(); // clear date selection
								if (title) {
									calendarApi.addEvent({
										id: new Date().getTime(),
										title,
										start: selectInfo.startStr,
										end: selectInfo.endStr,
										allDay: selectInfo.allDay,
									});
								}
							}}
							eventClick={(clickInfo) => {
								if (window.confirm("¿Esta seguro que desea eliminar el bloque de horas seleccionado?")) {
									clickInfo.event.remove();
								}
							}}
							eventAdd={async (info) => {
								const hours = { ...currentArea.hours };
								hours[info.event.id] = {
									id: info.event.id,
									title: info.event.title,
									start: info.event.start.getTime(),
									end: info.event.end.getTime(),
								};
								await firestore.update(`areas/${currentArea.id}`, { hours });
							}}
							eventChange={async (info) => {
								const hours = { ...currentArea.hours };
								hours[info.event.id] = {
									id: info.event.id,
									title: info.event.title,
									start: info.event.start.getTime(),
									end: info.event.end.getTime(),
								};
								await firestore.update(`areas/${currentArea.id}`, { hours });
							}}
							eventRemove={async (info) => {
								const hours = { ...currentArea.hours };
								delete hours[info.event.id];
								await firestore.update(`areas/${currentArea.id}`, { hours });
							}}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default React.memo(Areas);
