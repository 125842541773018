import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { Navbar, Container, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import logo from "../../logos/10.png";
import "./navbar.css";

const NavigationBar = ({ auth, authorized, setAuthorized, contextualMenu, setContextualMenu }) => {
	const navigate = useNavigate();

	return (
		<div>
			<Navbar bg="light" expand={false} collapseOnSelect={true} onSelect={(e) => !e.startsWith("#contextualMenu") && setContextualMenu(undefined)}>
				<Container>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Brand href="#">
						<img style={{ height: 40 }} src={logo} />
					</Navbar.Brand>
					<div style={{ width: 16 }}>
						{contextualMenu?.items && (
							<Dropdown drop="start">
								<Dropdown.Toggle
									as={React.forwardRef(({ children, onClick }, ref) => (
										<i
											className="bi bi-three-dots-vertical"
											ref={ref}
											style={{ color: "white", fontSize: 20, cursor: "pointer" }}
											onClick={(e) => {
												e.preventDefault();
												onClick(e);
											}}
										></i>
									))}
								></Dropdown.Toggle>
								<Dropdown.Menu>
									{contextualMenu.items.map((item, index) => (
										<div key={index}>{item}</div>
									))}
								</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="me-auto">
							{!authorized && (
								<Nav.Link href="#signIn" as={Link} to="/">
									Iniciar sesión
								</Nav.Link>
							)}
							{authorized && (
								<div>
									<Nav.Link href="rent" as={Link} to="/">
										Alquilar puesto
									</Nav.Link>
									<Nav.Link href="#reloadBalance">Recargar saldo</Nav.Link>
									<hr />
									<Nav.Link href="rents" as={Link} to="/rents">
										Ocupación
									</Nav.Link>
									<Nav.Link href="collection" as={Link} to="/collection">
										Recaudación
									</Nav.Link>
									{/* <Nav.Link href="#issuePenalty">Emitir multa</Nav.Link> */}
									<NavDropdown title="Administrar servicio">
										<NavDropdown.Item href="#service/setSpaces" as={Link} to="/areas">
											Administrar puestos
										</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="#service/users">Administrar usuarios y roles</NavDropdown.Item>
									</NavDropdown>
									<hr />
									<NavDropdown title="Administrar cuenta">
										{/* <NavDropdown.Item href="#account/setVehicles">Administrar vehículos</NavDropdown.Item> */}
										{/* <NavDropdown.Divider /> */}
										<NavDropdown.Item href="#account/changePassword">Cambiar contraseña</NavDropdown.Item>
									</NavDropdown>
									<hr />
									<Nav.Link
										href="#signOut"
										onClick={async () => {
											try {
												await signOut(auth);
											} finally {
												setAuthorized(false);
												navigate("/");
											}
										}}
									>
										Cerrar sesión
									</Nav.Link>
								</div>
							)}
							<hr />
							<Nav.Link href="#contactUs">Contáctanos</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};

export default React.memo(NavigationBar);
