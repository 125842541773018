export const setLabels = (parentElement) =>
{
	for (const element of parentElement.querySelectorAll("[placeholder]:not(.hidePlaceholder)"))
	{
		const label = document.createElement("label");
		label.className = "formElementLabel";
		label.innerText = element.getAttribute("placeholder");
		label.innerText = label.innerText.indexOf("(") > -1 ? label.innerText.substring(0, label.innerText.indexOf(" (")) : label.innerText;
		element.parentNode.insertBefore(label, element.nextSibling);
	}
};