import React from "react";
import { Container, ListGroup, Row, Col, Badge } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import "./spaces.css";

const Block = ({ block, generateAreaControls, setModal, spaces, setCurrentArea }) => {
	const firestore = useFirestore();

	const addSpace = (side) =>
		setModal({
			title: `Nuevo puesto`,
			controls: generateAreaControls(),
			onsubmit: async () => {
				let spaceName = document.getElementById("areaName").value;
				if (!/^\d{1,4}$/g.test(spaceName)) {
					alert("El identificador del puesto debe ser un número entero con una longitud maxima de 4 digitos y sin espacios en blanco.");
					return;
				}
				spaceName = spaceName.padStart(4, 0);
				const exists = (await firestore.collection("areas").where("type", "==", "space").where("name", "==", spaceName).where("deleted", "==", false).get()).docs.length;
				if (exists) {
					alert("Ya existe un puesto con este identificador.");
					return;
				}
				await firestore.collection("areas").add({
					name: spaceName,
					location: document.getElementById("areaLocation").value,
					type: "space",
					containers: [...block.containers].concat(block.id),
					deleted: false,
					active: true,
					creationDate: new Date(),
					hourlyFee: document.getElementById("hourlyFee").value ? parseFloat(document.getElementById("hourlyFee").value) : 0,
					dailyFee: document.getElementById("dailyFee").value ? parseFloat(document.getElementById("dailyFee").value) : 0,
					hours: block?.hours || null,
					side,
				});
			},
		});

	const side = (side) => <Col style={{ padding: 0 }}>
		<ListGroup variant="flush" activeKey={`#addSpace`}>
			{spaces
				.filter((space) => space.side === side)
				.sort((a, b) => (a.creationDate < b.creationDate ? -1 : 1))
				.map((space, index) => (
					<ListGroup.Item key={index} href={`#area${space.id}`} action onClick={() => setCurrentArea(space)}>
						{space.name} {!generateAreaControls && <Badge pill bg={space.available ? "danger" : "success"}>{space.available ? "Disponible" : "Alquilado"}</Badge>}
					</ListGroup.Item>
				))}
			{generateAreaControls && <ListGroup.Item href={`#addSpace`} action onClick={() => addSpace(side)} className="d-flex justify-content-between">
				<div style={{ whiteSpace: "nowrap" }}>Agregar&nbsp;</div>
				<i className="bi bi-plus-circle" style={{ color: "white" }}></i>
			</ListGroup.Item>}
		</ListGroup>
	</Col>;

	return (
		<div className="spaces">
			{block.intersection1.id !== "end" && (
				<div className="intersection" style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }}>
					—&nbsp;&nbsp;—&nbsp;&nbsp;{block.intersection1.name}&nbsp;&nbsp;—&nbsp;&nbsp;—
				</div>
			)}
			<Container>
				<Row>
					{side("left")}
					<Col className="streetName">
						<span>—&nbsp;&nbsp;—&nbsp;&nbsp;{block.streetName}&nbsp;&nbsp;—&nbsp;&nbsp;—</span>
					</Col>
					{side("right")}
				</Row>
			</Container>
			{block.intersection2.id !== "end" && (
				<div className="intersection" style={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4, whiteSpace: "nowrap" }}>
					—&nbsp;&nbsp;—&nbsp;&nbsp;{block.intersection2.name}&nbsp;&nbsp;—&nbsp;&nbsp;—
				</div>
			)}
		</div>
	);
};

export default React.memo(Block);
